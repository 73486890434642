<template>
  <div>
    <HeaderContent :list="list" label="Playback Recorded Live Streaming" marginBottom="16">
      <custom-button
              color="primary"
              class="white--text"
              @click="handleClick"
      >
        {{ $t("button.playbackLive") }}
      </custom-button>
    </HeaderContent>

    <div class="mt-6">
      <v-data-table
              :headers="headers"
              hide-default-footer
              class="grey--text"
              :class="p['my-table']"
              :items="lives"
      >
        <template v-slot:body="{ items }">
          <tbody v-for="item in items" :key="item.id" >
          <tr>
            <td :class="p['tb__border']">
              <div :class="p['tb__caption']">{{ item.roomId }}</div>
            </td>
            <td :class="p['tb__border']">
              <div :class="p['tb__caption']">{{ item.taskId }}</div>
            </td>
            <td :class="p['tb__border']">
              <div :class="p['tb__caption']">{{ item.streamUrl }}</div>
            </td>
            <td :class="p['tb__border']">
              <div :class="p['tb__caption']">{{ item.status }}</div>
            </td>
            <td :class="p['tb__border']">
              <div :class="p['tb__caption']">{{ item.userName }}</div>
            </td>
            <td :class="p['tb__border']">
              <div :class="p['tb__caption']">{{ item.description }}</div>
            </td>
            <td :class="p['tb__border']">
              <div :class="p['tb__caption']" v-if="item.status === 'true'">
                <v-btn dark @click="handleCancel(item.roomId)">
                  <v-icon dark left>remove_circle</v-icon>Cancel
                </v-btn>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>

      <div class="d-flex justify-end mt-10">
        <v-pagination
                class="d-flex align-center"
                v-model="page"
                :length="totalPages"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                :total-visible="6"
                @input="refreshDataLive"
        ></v-pagination>
      </div>

      <v-snackbar v-model="snackbar" :timeout="timeout" outlined top color="warning">
        <div v-if="errorObject">
          <div v-if="errorObject.response">
            <div v-if="errorObject.response.status === 401">
              <div>{{ errorObject.response.data.error }}</div>
              <div>{{ errorObject.response.data.error_description }}</div>
            </div>
            <div v-else>
              <div>{{ errorObject.response.data.message }}</div>
              <div>{{ errorObject.response.data.data }}</div>
            </div>
          </div>
          <div v-else>  {{ errorObject }}</div>
        </div>
      </v-snackbar>
      <v-alert :class="p.alert" :value="alertError" type="error">
        {{ errorMessage }}
      </v-alert>
      <v-overlay absolute :value="isOverlay">
        <v-btn color="success">
          Loading .....
        </v-btn>
      </v-overlay>
      <!-- DIALOG -->
      <v-dialog max-width="900" @click:outside="closeDialog"  v-model="isDialog" >
        <v-card  class="pa-4">
          <div class="mb-2">Sure to stop record live streaming?</div>
          <div class="d-flex justify-end mt-2" style="gap : 8px">
            <custom-button @click="closeDialog" size="small">Batalkan</custom-button>
            <custom-button @click="stopRecordedStreaming" size="small" color="primary">Stop</custom-button>
          </div>
        </v-card>
      </v-dialog>
      <!-- DIALOG -->
      <v-dialog max-width="900" @click:outside="closeDialog"  v-model="isPlayback" >
        <v-card  class="pa-4">
          <div class="mb-2">Start New Record Live Streaming</div>
          <div class="input-wrapper" style="margin-top: 10px;">
            <span>UserName: </span>
            <input type="text" :class="p['tb__input']" v-model="userName" placeholder="Enter username of the live streamer"/>
          </div>
          <div class="input-wrapper" style="margin-top: 10px;">
            <span>Description: </span>
            <input type="text" :class="p['tb__input']" v-model="description" placeholder="Enter description of the live"/>
          </div>

          <div class="input-wrapper" style="margin-top: 10px;">
            <span>StreamUrl: </span>
            <input type="text" v-model="streamUrl" :class="p['tb__input']" placeholder="Enter url of the recorded live video file"/>
          </div>

          <div class="d-flex justify-end mt-2" style="gap : 8px">
            <custom-button @click="resetDialog" size="small">Reset Data</custom-button>
            <custom-button @click="closeDialog" size="small">Batalkan</custom-button>
            <custom-button @click="startRecordedStreaming" :disabled="isDisabled"
                           size="small" color="primary">Start</custom-button>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import HeaderContent from "@/containers/HeaderContent";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  components: {
    HeaderContent
  },
  mounted() {
    this.onInitiateFetchLives(1)
  },
  computed: {
    ...mapState({
      totalPages: (state) => state.recordedLive.totalPages,
      totalElements: (state) => state.recordedLive.totalElements,
      lives : (state) => state.recordedLive.lives,
      sortBy : (state) => state.recordedLive.sortBy
    }),
    choosenItem : {
      get () {
        return this.selected
      },
      set(value) {
        this.$emit('setSelectedItem' , value)
      }
    },
  },
  methods : {
    ...mapActions({
      fetchLives: "recordedLive/fetchRecordedLives",
      stopLiveStreaming: "recordedLive/stopLiveStreaming",
      startLiveStreaming: "recordedLive/startLiveStreaming",
    }),
    async refreshDataLive() {
      const valuePage = this.page
      return this.onInitiateFetchLives(valuePage)
    },
    onInitiateFetchLives(page) {
      const sort = "create_at,DESC";
      const payload = {
        size: 10,
        page : page - 1,
        ...(sort && { sort: sort })
      }
      return this.fetchLives(payload)
              .then(r => {
                return r
              })
              .catch(err => {
                this.snackbar = true
                this.errorObject = err
              })
    },
    handleClick() {
      this.isPlayback = true
    },
    handleCancel(params) {
      this.isDialog = true
      this.roomId = params
    },
    setSelectedItem (value) {
      this.selected = value
    },
    closeDialog() {
      this.isDialog = false
      this.isPlayback = false
      this.isDisabled = false
      this.roomId = ""
      this.streamUrl = ""
      this.userName = ""
      this.description = ""
    },
    resetDialog() {
      this.isDialog = false
      this.roomId = ""
      this.streamUrl = ""
      this.userName = ""
      this.description = ""
    },
    startRecordedStreaming() {
      this.isDisabled = true;
      const payload = {
        streamUrl: this.streamUrl,
        userName: this.userName,
        description: this.description,
      }
      return this.startLiveStreaming(payload)
              .then(() => {
                this.closeDialog()
                this.refreshDataLive()
              })
    },
    stopRecordedStreaming() {
      const payload = {
        roomId: this.roomId
      }
      return this.stopLiveStreaming(payload)
              .then(() => {
                this.closeDialog()
                this.refreshDataLive()
              })
    },
    ...mapMutations({
      setLives: "recordedLive/setLives",
      setSortBy : 'recordedLive/setSortBy'
    }),
  },
  data() {
    return {
      isOverlay: false,
      timeout: 3000,
      isDialog: false,
      isPlayback: false,
      alertError: false,
      roomId: "",
      streamUrl: "",
      userName: "",
      description: "",
      snackbar: false,
      errorObject: null,
      errorMessage: "",
      isDisabled: false,
      selected: [],
      list: [
        {
          text: "Recorded Live",
          disabled: true,
        },
      ],
      page: 1,
      headers: [
        {
          text: "Room",
          sortable: false,
          value: "roomId",
          filterable: false,
          width: "120",
        },
        {
          text: "Task",
          value: "taskId",
          sortable: false,
          filterable: false,
          width: "120",
        },
        {
          text: "Url",
          sortable: false,
          filterable: false,
          value: "streamUrl",
          width: "250",
        },
        {
          text: "Status",
          sortable: false,
          filterable: false,
          value: "status",
          width: "100",
        },
        {
          text: "UserName",
          sortable: false,
          filterable: false,
          value: "userName",
          width: "150",
        },
        {
          text: "Description",
          sortable: false,
          filterable: false,
          value: "description",
          width: "200",
        },
        {
          text: "Action",
          sortable: false,
          filterable: false,
          value: "action",
          width: "100",
        },
      ],
    };
  },
}
</script>

<style lang="scss" module="p">
  .my-table thead th {
    background-color: #fafafa;
  }

  .font-content {
    font-size: 12px;
    font-weight: 500;
  }

  .label {
    color: #BBBBBB;
    @extend .font-content;
  }
  .alert-label {
    color: var(--White, #FFF);
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
  }
  .alert-box {
    border-radius: 4px;
    background: #E76F00;
    display: flex;
    padding: 8px 12px;
    gap: 9px;
    display: flex;
    margin-left: 32px;
  }
  .sublabel {
    color: black;
    @extend .font-content;
    margin-left: 2px;
  }

  .alert {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .input-search {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    height: 32px;
    font-size: 12px;
    padding: 9px;

    &:focus {
      outline: none;
    }
  }

  .fonts {
    font-size: 12px;
    font-weight: 500;
    color: #777777;
  }

  .tb {
    &__border {
      border-bottom: 1px solid var(--White-Smoke, #EEE);
    }

    &__input {
      border-bottom: 1px solid var(--White-Smoke, #EEE);
      width: 80%;
    }

    &__caption {
      @extend .fonts;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: block;
      word-break: break-all;
      max-width: 250px;
    }
  }
</style>